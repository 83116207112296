// import { p } from '@mui/material';
// import AvaYoutubeVideo from '../../../renderer/src/components/Ava/AvaYoutubePlayer';
import LanguageManager from '@common/LanguageManager/LanguageManager';

const lang_de = {
  switch_profile: 'Profil wechseln',
  install_profile_switcher: 'Profile-Switcher (ALPHA) installieren',
  profile_switcher_installed: 'Profile Switcher (ALPHA) installiert',
  number_punctuation: ',',
  searching_for_update: 'Suche nach Updates',
  successfully_updated: 'Update erfolgreich installiert',
  no_update_available: 'Kein Update verfügbar',
  downloading_new_update: 'Update verfügbar, wird heruntergeladen',
  installing_new_update: 'Update wird installiert',
  network_error_while_updating:
    'Deine Internetverbindung ist leider zu schwach. Bitte versuche später es erneut.',
  update_error_mac_access: 'Update fehlgeschlagen. Mac Berechtigungsfehler',
  update_error_read_only_volume:
    'Update fehlgeschlagen. Fehler: Read only volume.',
  update_error_generic: 'Fehler beim updaten',

  update_available: 'Update verfügbar',
  ask_user_to_update:
    'Es gibt eine neue Version des Social Selling Cockpits. Möchtest du neu starten und das Update jetzt installieren?',
  install_update_now: 'Update jetzt installieren',
  install_update_later: 'Später installieren',

  create_ticket: 'Fehler melden',
  avas_tip: "Ava's Tipp",
  ticket_submitted: 'Dein Ticket wurde erfolgreich erstellt!',
  ticket_we_will_solve_the_ticket_soon:
    'Wir freuen uns über deinen Input zu dem, was nicht funktioniert, was du erwartest oder dir wünschst! Wir werden deine Anfrage schnellstmöglich bearbeiten und uns dann per E-Mail bei dir melden. Bitte schau auch in deinem Spam-Ordner nach, falls du nichts von uns hörst.',
  ticket_back_to_ssc: "Weiter geht's!",
  is_there_a_problem: 'Gibt es ein Problem?',
  ticket_title_name: 'Titel',
  ticket_description_placeholder: 'Sag uns, was nicht funktioniert',
  ticket_submit: 'Ticket abschicken',
  report_problem: 'Problem melden',

  production_flag_text:
    'Dies ist derzeit noch eine BETA-Version, daher kann es noch zu Fehlern oder unerwartetem Verhalten kommen. Falls etwas nicht so funktioniert, wie du es dir vorstellst, kannst du einfach auf Ava klicken und uns eine Nachricht schreiben.',
  build_flag_text: 'Das ist ein {RELEASE_CHANNEL} Build',
  account: 'Account',
  zoom: 'Zoom',
  cancel: 'Abbrechen',
  continue: 'Weiter',
  apply: 'Anwenden',
  skip: 'Überspringen',
  skip_once: 'Einmal überspringen',
  ok: 'OK',
  delete: 'Löschen',
  discard: 'Verwerfen',
  close: 'Schließen',
  send: 'Senden',
  back: 'Zurück',
  retry: 'Erneut versuchen',
  no: 'Nein',
  yes: 'Ja',
  save: 'Speichern',
  dont_save: 'Nicht speichern',
  understood: 'Verstanden',
  understood_and_dont_show_again: 'Verstanden und nicht mehr anzeigen',
  are_you_sure_irreversible_deletion:
    'Bist du dir sicher? Das Löschen kann nicht rückgängig gemacht werden.',

  thanks_for_your_support: 'Danke für deine Hilfe!',
  we_will_solve_the_ticket_soon:
    'Wir kümmern uns schnellstmöglich um dein Ticket und melden uns bei dir, falls wir noch weitere Informationen benötigen.',
  restart_due_to_error: (
    <>
      Wegen des Fehlers muss das Social Selling Cockpit leider einmal landen.
      Bitte starte das SSC neu, um weiterzumachen.
      <br />
      Guten Weiterflug!
    </>
  ),
  quit_ssc: 'SSC beenden',
  something_went_wrong: 'Oups! Hier ist etwas schiefgegangen :(',
  unknown_error: 'Unbekannter Fehler',
  we_will_be_up_and_running_soon:
    'Wir kümmern uns schnellstmöglich darum, dass alles wieder glatt läuft!',
  please_explain_the_error:
    'Kannst du uns noch erzählen, was du gemacht hast, bevor der Fehler aufgetreten ist?',
  error_title: 'Titel',
  error_description: 'Fehlerbeschreibung',
  send_error_report: 'Abschicken',

  download_file: 'Datei herunterladen',
  error_while_loading_chat: 'Beim Laden des Chats ist ein Fehler aufgetreten',
  start_of_the_chat_with: 'Beginn des Chats mit {name}. Viel Erfolg!',
  new_message: 'Neue Nachricht',
  message_deleted: 'Nachricht gelöscht',
  react: 'Reagieren',

  not_all_variables_replaced_in_message:
    'Es sind noch nicht alle Variablen ersetzt. Bitte ersetze diese, bevor du die Nachricht absendest.',
  on_send_placeholders_are_removed:
    'Beim Senden werden alle Platzhalter entfernt',
  confirm_send_with_variables: 'Trotzdem senden',

  profile_picture: 'Profilbild',

  text_successfully_copied: 'Text kopiert',
  meeting_with: 'Meeting mit {name}',

  no_selection: 'Keine Auswahl',

  emojis_person: 'Personen',
  emojis_nature: 'Natur',
  emojis_food: 'Essen & Trinken',
  emojis_activities: 'Aktivitäten',
  emojis_places: 'Reisen & Orts',
  emojis_objects: 'Objekte',
  emojis_symbols: 'Symbole',

  favorites: 'Favoriten',

  upload_files_here: 'Hier Dateien hochladen',

  placeholder: 'Platzhalter',
  fullname: 'Voller Name',
  firstname: 'Vorname',
  lastname: 'Nachname',

  fullname_explanation:
    'Hier wird der volle Name deines Chatpartners eingetragen. (bsp. Max Mustermann)',
  firstname_explanation:
    'Hier wird der Vorname deines Chatpartners eingetragen. (bsp. Max)',
  lastname_explanation:
    'Hier wird der Nachname deines Chatpartners eingetragen. (bsp. Mustermann)',

  type_a_message: 'Schreibe eine Nachricht...',

  recording_error_mic_settings: 'Bitte überprüfe deine Mikrofon Einstellungen.',
  voice_recording: 'Sprachnachricht',

  skip_step: 'Schritt überspringen',
  confirm_skip_step:
    'Bist du dir sicher, dass du diesen Schritt überspringen möchtest? Du kannst später nicht mehr zurückkehren.',
  skip_task: 'Aufgabe überspringen',
  confirm_skip_task:
    'Bist du dir sicher, dass du diese Aufgabe überspringen möchtest? Du kannst später nicht mehr zurückkehren.',

  get_a_coffee: 'Hol dir am besten einen Kaffee',
  we_are_processing:
    'Wir verarbeiten gerade circa ein drölftel des Internets. Das könnte also ein paar Minuten dauern.',
  only_solutions_quote: (
    <>
      In der Luftfahrt gibt es keine Probleme, nur{' '}
      <span style={{ color: '#0062ff' }}>Lösungen</span>.
    </>
  ),

  login_to_your_account: 'Melde dich mit deinem Account an',
  userid: 'User-ID',
  email: 'E-Mail',
  login_code: 'Code',
  wrong_email_or_code: 'Falsche E-Mail oder Code',
  please_wait_seconds_before_resending:
    'Bitte warte {seconds} Sekunden, bevor du es erneut versuchst.',
  generic_error_try_again:
    'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  code_sent: 'Code wurde versendet',
  email_not_registered: 'Diese E-Mail ist nicht registriert',
  error_requesting_code: 'Fehler beim Anfordern des Codes',
  please_wait_seconds_before_requesting_code:
    'Bitte warte {seconds} Sekunden, bevor du einen neuen Code anforderst.',
  no_email_delivered: 'Keine Email erhalten?',
  request_new_code: 'Neuen Code anfordern',
  no_account_yet: 'Noch keinen Account?',
  register_now: 'Jetzt registrieren',

  terminal: 'Terminal',
  changelog: 'Update',
  faq_call: 'FAQ Call',
  faq_call_wrong_time: 'Die Meetings sind immer Montags um 18:00 Uhr',

  start_now_on_linkedin: 'Jetzt auf LinkedIn abheben',
  already_logged_in: 'Du bist bereits eingeloggt',
  greeting_with_name: (name: string) => `Hi ${name}!`,
  general_greeting: 'Hi!',
  logout: 'Ausloggen',
  we_are_working_on_it: 'Wir arbeiten daran!',
  today_no_video: 'Heute kein Video - Heute wird umgesetzt!',
  come_back_tomorrow: 'Schau morgen wieder vorbei!',

  are_you_sure: 'Bist du dir sicher?',

  no_persons_added_yet: 'Du hast noch keine Person hinzugefügt',

  error_while_opening_chat:
    'Es gab leider einen Fehler beim Öffnen des Chats. Bitte versuche es später erneut.',
  reload: 'Neu laden',

  image: 'Bild',
  file: 'Datei',

  generic_error: 'Ein Fehler ist aufgetreten',

  personal_contact: 'Persönlicher Kontakt',
  audience_holder: 'Zielgruppenhalter',
  potential_customer: 'Potenzieller Kunde',
  customer: 'Kunde',
  no_match: 'Kein passender Kunde',
  uncategorized: 'Unkategorisiert',

  select_relation_step: 'Beziehungsschritt auswählen',
  cant_write_in_this_stage:
    'Du kannst in diesem Schritt keine Nachrichten schreiben',

  make_notes_about_person: 'Hier kannst du dir Notizen zu der Person machen',

  categorization: 'Kategorisierung',
  categorize: 'Kategorisieren',

  categorize_person: (name: string) =>
    `Bitte sortiere ${name} in eine der Kategorien ein.`,

  find_audience_holders:
    'Finde Zielgruppenhalter für deine Kunden, um später effizienter zu arbeiten.',

  enough_audience_holder_selected:
    'Die hast genug Zielgruppenhalter ausgewählt!',
  enough_audience_holder_selected_goto_next_step:
    "Du hast genug Zielgruppenhalter ausgewählt. Wenn du noch weitere kennst, kannst du die noch schnell auswählen, sonst geht's am besten weiter zum nächsten Schritt.",
  select_more: 'Mehr auswählen',
  next_step: 'Nächster Schritt',

  do_not_chat_while_searching_audience_holders:
    'Suche erst Zielgruppenhalter, zum Chatte ist später noch Zeit.',

  categorize_existing_contacts: 'Kategorisiere deine bestehenden Kontakte',

  open_chat: 'Chat öffnen',
  no_chat_available: 'Kein Chat vorhanden',

  recategorize: 'Neu einordnen',
  write_person_later: 'Später bei {name} melden',
  close_profile: 'Profil schließen',
  open_profile: 'Profil öffnen',
  message_already_sent: 'Nachricht bereits gesendet',

  skip_person: 'Person überspringen',
  skip_name: (name: string) => `${name} überspringen`,
  skip_name_until: (name: string) =>
    `Bist wann soll ${name} übersprungen werden?`,

  chat: 'Chat',
  chat_followup: 'Chat Follow-Up',
  chat_task_description:
    'Schreibe Nachrichten an deine Kontakte, baue Beziehungen aus und vereinbare Meetings.',

  chat_tutorial: 'Chat Tutorial',
  mitch_explains: "Mitch erklärt's",
  cant_recategorize_in_tutorial:
    'Einen Kontakt neu einzuordnen ist im Tutorial nicht möglich. Du kannst hier aber trotzdem die verschiedenen Kategorien sehen und dir überlegen, wie du den Kontakt einordnen würdest.',
  skip_tutorial: 'Tutorial überspringen',

  write_comments: 'Schreibe Kommentare',
  write_comments_description:
    'Schreibe Kommentare zu Beiträgen, um dein\n Netzwerk und deinen Expertenstatus zu erweitern.',

  time_is_up: 'Zeit ist um!',
  you_reached_the_max_time_for_commenting:
    'Du hast die empfohlene maximale Zeit für diese Aufgabe erreicht. Du kannst noch schnell den letzten Kommentar schreiben oder gleich zum nächsten Schritt weiter gehen.',
  one_more_comment: 'Nur noch ein Kommentar',

  maximum_reached: 'Maximum erreicht',
  maximum_comment_reached: (
    <>
      <strong>Seht gut!</strong> Du hast die empfohlene Anzahl an Kommentaren
      für heute erreicht! <br />
      Falls du doch noch mehr Kommentare schreiben willst, kannst du das gerne
      tun, sonst geht es weiter zum nächsten Schritt.
    </>
  ),
  comments_written: 'Kommentare geschrieben',

  no_chatting_comments_task:
    'Schreibe erstmal ein paar Kommentare, zum Anschreiben kommen wir später.',

  change_audience_holder: 'Zielgruppenhalter wechseln',

  connect: 'Vernetzen',
  connect_task_description:
    'Entscheide, ob ein vorgemerkter Kontakt als potentiell Kunde in Frage kommt und vernetze dich.',
  send_connection_request: 'Verbindungsanfrage senden',
  check_if_fitting_to_connect:
    'Überprüfe, ob {name} ein\npassender Kunde für dich ist und du dich vernetzen möchtest.',

  connect_via_sidebar_buttons:
    'Wähle zum Vernetzen einfach den Kontakttyp aus, den du mit der Person verbindest.',
  connect_as_potential_customer: (
    <>
      Als{' '}
      <span
        style={{
          fontWeight: 'bold',
          display: 'inline',
          wordBreak: 'break-word'
        }}>
        potentieller Kunde
      </span>{' '}
      vernetzen
    </>
  ),
  connect_as_audience_holder: (
    <>
      Als{' '}
      <span
        style={{
          fontWeight: 'bold',
          display: 'inline',
          wordBreak: 'break-word'
        }}>
        Ziel
        <wbr />
        gruppen
        <wbr />
        halter
      </span>{' '}
      vernetzen
    </>
  ),
  connect_later: 'Später vernetzen',
  dont_connect: 'Nicht vernetzen',
  is_relation_established: `Hast du bereits eine Beziehung mit {name} aufgebaut?`,
  relation_established: 'Beziehung aufgebaut',
  relation_not_established: 'Beziehung noch nicht aufgebaut',
  establish_relation: 'Beziehung aufbauen',
  establish_relation_to_person:
    'Baue eine persönliche Beziehung zu {name} auf.',

  you_reached_max_time_for_marking_contacts:
    'Du hast die empfohlene maximale Zeit für diese Aufgabe erreicht. Du kannst noch schnell den letzten neuen Kontakt suchen oder gleich zum nächsten Schritt weiter gehen.',
  one_more_contact: 'Nur noch einen Kontakt',
  maximum_marked_contacts_reached: (
    <>
      <strong>Seht gut!</strong> Du hast die maximale Anzahl an potentiellen
      Kontakten für heute erreicht! <br />
      Falls du doch nochmal die Profile durchsortieren möchtest, kannst du das
      gerne tun, sonst geht es weiter zum nächsten Schritt.
    </>
  ),
  max_marked_contacts_reached_warning: `Du kannst maximal {MAX} Kontakte pro Tag hinzufügen. Entferne erst einen Kontakt, bevor du einen neuen hinzufügst.`,
  x_marked_profiles: 'vorgemerkte Profile',
  find_potential_customers_before_chatting:
    'Suche erst ein paar interessante Personen raus, zum Anschreiben kommen wir später.',

  expand_network: 'Netzwerk erweitern',
  expand_network_description:
    'Suche neue, interessante Kontakte raus\nmit denen du dich potentiell später vernetzen möchstest.',

  onboarding_video: 'Onboarding Video',
  onboarding_video_title: 'Onboarding: {title}',

  skip_question: 'Frage überspringen',
  relationship_questions: 'Status-Fragen',
  relationship_questions_description:
    'Das SSC wird dir Fragen zum Beziehungsstatus stellen, um deine Kontakte richtig einzuordnen.',
  one_step: '1 Schritt',
  multiple_steps: (count: number) => `${count} Schritte`,
  time_needed: 'Benötigte Zeit',
  resume_last_session: 'Letzte Session weiter machen',
  restart_focus_mode: 'Fokus Mode neu starten',
  tasks: 'Aufgaben',
  lets_go: "Los geht's!",
  welcome_to_focus_mode: 'Willkommen im Fokus Modus des SSC!',
  focus_mode_description:
    'Im Fokus Modus kannst du dich gezielt auf eine Aufgabe konzentrieren. Das SSC hilft dir dabei rauszusuchen, was gerade wichtig ist und wie du es am besten angehst. Wenn du den Fokus Modus startest, nehme dir am besten entsprechend Zeit und Ruhe, um dich voll und ganz auf die Aufgabe konzentrieren zu können.',
  back_to_manual_mode: 'Zurück zum manuellen Modus',
  sprint: 'Sprint',
  medium: 'Medium',
  intensive: 'Intensiv',

  done_for_today: 'Du bist für heute fertig!',
  continue_in_manual_mode: 'Weiter im manuellen Modus',

  summary: 'Zusammenfassung',
  summary_of_focus_mode: 'Zusammenfassung des Fokus Modus',

  leave_focus_mode_confirmation:
    'Wenn du den Fokus-Modus verlässt, können nicht gespeicherte Daten verloren werden. Außerdem kannst du nicht an dieser Stelle weitermachen, wenn du den Fokus-Modus erneut startest. Möchtest du wirklich den Fokus-Modus verlassen?',
  leave_focus_mode: 'Fokus-Modus verlassen',

  linkedin_login: 'LinkedIn Login',
  wrong_linkedin_account: 'Falscher LinkedIn Account',
  wrong_linkedin_account_explanation: (
    <>
      Bitte melde dich mit deinem verknüpften LinkedIn Account an!
      <br />
      <br />
      Aktuell unterstützt das Social Selling Cockpit nur einen LinkedIn Account.
      <br />
      Melde dich daher bitte mit deinem ursprünglichen LinkedIn Account an.
    </>
  ),
  processing_linkedin_data: 'LinkedIn Daten verarbeiten',
  getting_login_status: 'Login Status abrufen',
  please_log_in_to_linkedin:
    'Bitte melde dich mit deinem LinkedIn Konto an. Bitte bedenke, dass du dafür auch die Richtlienen von LinkedIn akzeptieren musst.',
  logging_out: 'Abmelden',

  login_error_wrong_format:
    'Es ist ein Formatierungsfehler aufgetreten. Bitte überprüfe, dass du die neuste Version des SSC verwendest.',
  login_error_not_paid:
    'Es gab ein Problem mit deiner Zahlung, bitte überprüfe deine Zahlungsinformationen oder kontaktiere uns.',
  login_error_internal_server_error:
    'Es gab ein Problem mit dem Server. Bitte versuche es später erneut.',
  login_error_too_many_requests:
    'Bitte warte 30 Sekunden, bevor du einen neuen Code anforderst.',

  we_are_working_on_the_ssc: 'Wir arbeiten gerade am SSC',
  server_maintenance_explanation:
    'Wir warten und aktualisieren aktuell unsere Server, weswegen du heute das Social Selling Cockpit leider nicht nutzen kannst. Schaue morgen wieder rein, dann kannst du wieder durchstarten!',
  even_the_busiest_bees_need_a_break:
    'Auch die fleißigste Biene braucht mal eine Auszeit.',

  not_yet_categorized: 'Noch nicht kategorisiert',

  chat_inbox: 'Inbox',
  search: 'Suchen',

  error_loading_contacts: 'Fehler beim Laden der Kontakte',
  error_while_loading: 'Fehler beim Laden',
  load_more_entries: 'Mehr laden',
  no_more_entries: 'Keine weiteren Einträge',

  mark_as_unread: 'Als ungelesen markieren',
  mark_as_read: 'Als gelesen markieren',
  archive: 'Archivieren',
  unarchive: 'Dearchivierung',
  customer_acquisition_process: <>Kunden&shy;gewinnungs&shy;prozess</>,

  close_pipeline: 'Pipeline schließen',
  did_you_close_contact: 'Hast du {name} erfolgreich als Kunde abgeschlossen?',
  contact_is_alredy_customer:
    '{name} ist bereits erfolgreicher gewonnener Kunde von dir. Gute Arbeit.',
  contact_is_no_match:
    '{name} hat sich nicht als Kunden qualifiziert. Vielleicht schaust du nochmal in 3-6 Monaten vorbei.',

  no_chat_selected: 'Kein Chat ausgewählt',
  categorize_before_chatting:
    'Du musst erst deine Kontakte kategorisieren bevor sie dir hier in deinem Chat Cockpit angezeigt werden.',

  open_chat_with: 'Chat mit {name} öffnen',
  no_chat_with: 'Du hast noch keinen Chat mit {name}.',

  open_profile_in_browser: 'Profil im Browser öffnen',

  pipeline: 'Potentieller Kunde',
  waiting: 'Wartend',

  no_contacts_categorize_first:
    'Starte die Kategorisierung um Leute hinzuzufügen.',
  no_fitting_contacts_found: 'Keine passenden Kontakte gefunden',
  no_more_contacts: 'Keine weiteren Kontakte',

  confirm_mark_as_audience_holder:
    'Willst du {name} nicht mehr als Zielgruppenhalter markieren?',

  create_discount_coupon: 'Neuen Rabattcoupons erstellen',
  discount_coupon_name_explanation:
    'Das ist der Name deines Rabattcoupons. Dieser ist nicht nur für die, sondern wird deinen Kunden auch angezeigt, wenn sie den Rabattcoupon eingegeben haben.',
  coupon_name: 'Name',
  code: 'Rabattcode',
  code_already_in_use: 'Dieser Rabattcoupon ist bereits vergeben.',
  code_not_allowed: 'Dieser Rabattcoupon ist nicht erlaubt.',
  code_available: 'Dieser Code ist noch verfügbar.',
  code_explanation:
    'Das ist der Rabattcoupon, den deine Kunden bei der Bestellung eingeben, um dir zugeordnet zu werden.',
  disocunt_coupon_provision_explanation:
    'Du kannst deinen Kunden bis zu 30% Rabatt geben. Alles was du deinen Kunden an Rabatt abgibst, geht allerdings von deiner Provision ab!',
  discount: 'Rabatt',
  provision: 'Provision',
  unsaved_changes: 'Ungespeicherte Änderungen',
  you_have_unsaved_changes:
    'Du hast noch ungespeicherte Änderungen. Bist du dir sicher, dass du abbrechen willst? Die Änderungen werden endgültig gelöscht.',
  no_discount_coupons: 'Keine Discount-Coupons vorhanden',
  no_freeToUse_coupons: 'Keine Free To Use-Coupons vorhanden',

  create_f2u_coupon: 'Neuen Free To Use-Coupon erstellen',
  f2u_coupon_name_explanation:
    'Das ist der Name deines Free To Use-Code. Dieser ist nicht nur für die, sondern wird deinen Kunden auch angezeigt, wenn sie den Free To Use-Code eingegeben haben.',
  free_months: 'Gratismonate',
  f2u_free_months_explanation: ({ price }: { price: number | string }) => (
    <>
      Mit dem Code kannst du deinen Kunden ein paar Gratismonate bescheren.{' '}
      <b>Diese Gratismonate musst du allerdings bezahlen!</b> Hierbei zahlst du
      allerdings lediglich 50% vom vollen Preis der Software, also {price}.
    </>
  ),
  create_coupon: 'Coupon erstellen',
  create_f2u_payment_warning: ({ price }: { price: number | string }) => (
    <>
      Wenn du diesen Code erstellt, wirst <b>du</b> 50% der Kosten für jeden
      Monat zahlen! Das heißt, für jeden Kunden, der diesen Code nutzt, zahlst
      du {price}. Bist du dir sicher, dass du diesen Code erstellen willst? Sei
      vor allem vorsichtig, dass du den Code nicht öffentlich teilst!
    </>
  ),
  active: 'Aktiv',
  name: 'Name',
  number_of_active_users: 'Aktive Nutzer',
  number_of_uses: 'Code Nutzungen',
  number_of_not_confirmed_users: 'Nicht bestätigte Nutzer',

  number_of_active_users_explanation:
    'Diese Anzahl an Nutzern hat den Coupon benutzt und hat aktuell ein aktives Abo. Diese Nutzer bringen dir also aktiv Provisionen.',
  number_of_uses_explanation:
    'Dies ist die Anzahl aller Nutzungen des Codes. Jeder Nutzer, der auf der Webseite den Code eingibt wird hier gezählt, egal ob er das Abo bestätigt hat und ob es noch aktiv ist.',
  number_of_not_confirmed_users_explanation:
    'Dies ist die Anzahl an Nutzern, die den Code eingegeben hat, aber das Abo nicht bestätigt hat. Diese Nutzer haben also auf der Webseite auf kaufen geklickt, ihre Daten eingegeben, dann aber nicht ihre Zahlungsinformationen hinterlegt und das Abo abgeschlossen.',

  code_copied: 'Code kopiert',
  confirm_deletion: 'Löschen bestätigen',
  confirm_delete_coupon: ({ code }: { code?: string }) => (
    <>
      Bist du dir sicher, dass du den Coupon "
      <b>{code ?? 'KEIN CODE AUSGEWÄHLT'}</b>" löschen willst?
    </>
  ),
  number_of_free_months: 'Anzahl Monate',
  total_costs: 'Gesamtkosten',
  discount_coupons: 'Discount-Coupons',
  f2u_coupons: 'Free To Use-Coupons',

  error_loading_balance: 'Fehler beim Laden des Guthabens',
  balance: 'Guthaben',
  balance_explanation:
    'Hier wird alles Geld angezeigt, was deine Kunden diesen Monat bereits bezahlt haben, sowie alle Gratismonate, die du deinen Kunden geschenkt hast. Diese Summe wird dir dann am Ende des Monats ausgezahlt oder abgehoben. Wenn deine Kunden diesen Monat noch nicht bezahlt haben, wird deine Provision auch noch nicht hier angezeigt. Sei also nicht beunruhigt, falls es aktuell noch weniger ist, als du erwartet hast.',
  customers: 'Kunden',
  new: 'Neu',
  basic: 'Basic',
  referrer: 'Referrer',
  use_ssc_to_become_basic:
    'Nutze das SSC für 10 Tage um ein Basis Nutzer zu werden.',
  finish_training_to_become_adavanced:
    'Wenn du das Pilotentraining abgeschlossen hast, wirst du zum fortgeschrittenen Nutzer.',
  ambassador: 'Botschafter',
  invite_users_to_become_ambassador:
    'Wenn du 3 Freunde eingeladen hast, wirst du zum Botschafter.',
  affiliate: 'Affiliate',
  apply_to_become_affiliate:
    'Um Affiliate zu werden, musst du dich bei uns bewerben.',
  error: 'Error',
  ssc_is_free_for_you: 'Das SSC ist jetzt für dich gratis!',
  apply_as_affiliate: 'Jetzt als Affiliate bewerben',
  you_have_maxed_out_referring: (
    <>
      Du hast die maximale Anzahl an Freunden eingeladen und bist jetzt ein
      Botschafter! Herzlichen Glückwunsch! <br />
      Damit ist das SSC jetzt für dich kostenlos! <br />
      Wenn du noch weitermachen willst, kannst du dich jetzt als Affiliate
      bewerben und dann sogar Provisionen verdienen!
    </>
  ),
  nice_that_you_use_ssc: 'Schön, dass du das SSC nutzt!',
  to_the_pilot_training: "Hier geht's zum Pilotentraining",
  congratulations_you_are_a_basic_user: (
    <>
      Da du jetzt schon 10 Tage das SSC nutzt, bist du ein Basis Nutzer!
      <br />
      Ab jetzt hast du das Pilotentraining freigeschaltet und kannst dein
      SSC-Piloten Zertifikat erhalten.
      <br />
      Wenn du dein Zertifikat meisterst, kannst du zum fortgeschrittenen Nutzer
      aufsteigen.
    </>
  ),
  you_the_ssc_further_to_unlock_training: (
    <>
      Nutze das Social-Selling-Cockpit weiter, damit du das Pilotentraining
      freischalten kannst.
      <br />
      Dann kannst du auch dein SSC-Piloten Zertifikat erhalten.
    </>
  ),
  default_price: 'Standardpreis',
  price: 'Preis',
  money_saved: 'Gespart',
  your_referral_code: 'Dein Referral-Code',
  referral_code_inactive: 'Dein Referral-Code ist inaktiv',
  referrer_status: 'Referrer Status',
  you_referred_x_users: ({
    count,
    max
  }: {
    count: string | number;
    max: string | number;
  }) => (
    <>
      Du hast das SSC {count} von {max} Nutzern weiterempfohlen.
    </>
  ),
  here_you_can_edit_your_subscription: 'Hier kannst du dein Abo verwalten:',
  edit_subscription: 'Abo verwalten',
  you_are_using_ssc_without_subscription: 'Aktuell nutzt du das SSC ohne Abo.',
  subscription: 'Subscription',
  progress: 'Fortschritt',
  settings: 'Einstellungen',
  edit_template: 'Nachricht bearbeiten',
  select_ssc_template: 'Vorlage auswählen',
  confirm_delete_template:
    'Bist du dir sicher, dass du diese Nachrichtenvorlage löschen willst?',
  insert_placeholder: 'Platzhalter einfügen',
  new_template: 'Neue Vorlage',
  onboarding_video_overview: 'Onboarding Video Übersicht',
  onboarding: 'Onboarding',
  reset_onboarding: 'Onboarding zurücksetzen',
  confirm_reset: 'Zurücksetzen bestätigen',
  confirm_reset_description: (
    <>
      {' '}
      Willst du wirklich alle Onboarding Videos die dir beim Onboarding
      angezeigt werden zurücksetzen? <br />
      Das bedeutet z.B., dass wenn du das nächste Mal das Dashboard öffnest, du
      wieder das Onboarding Video zu sehen bekommst. <br />
      Alle Onboarding Videos findest du auch unter "Onboarding Video Übersicht".
    </>
  ),
  reset: 'Zurücksetzen',

  welcome: 'Willkommen',
  pilot_training: 'SSC Piloten Training',
  module: 'Modul',
  messages: 'Nachrichten',
  content: 'Content',
  comments: 'Kommentare',
  sell: 'Verkaufen',
  certificat: 'Zertifikat',
  coming_soon: 'Coming Soon',
  this_section_is_under_construction:
    'Dieser Bereich ist noch in Arbeit, schau später nochmal vorbei',
  loading: 'Laden',
  ssc_training: 'SSC Training',
  use_ssc_to_unlock_training:
    'Nutz das SSC weiter, dann kannst du bald hier trainieren!',
  training_program: 'Trainingsprogramme',
  linkedin: 'LinkedIn',
  dashboard: 'Dashboard',
  chat_cockpit: 'Chat Cockpit',
  templates: 'Vorlagen',
  community: 'Community',
  focus_mode: 'Fokus Modus',
  inmail_not_supported:
    'Aktuell haben wir noch keinen Chat zu der Person. Bitte kategorisiere die Person als potentieller Kunde und beginne einen Chat mit ihr im Fokus-Modus.',
  hi_im_ava: 'Hi! Ich bin Ava',
  ava_greeting:
    'Ich bin Ava dein persönlicher Assistent. Ich werde dir helfen das Beste aus deiner Zeit zu machen. Ich werde dich durch den Setup-Prozess führen und während deiner Zeit im Social-Selling-Cockpit für dich da sein. Du musst nur auf mich klicken, dann bin ich sofort für dich da!',
  starting_soon: "Gleich geht's los!",
  we_recomment_linkedin_premium:
    'Wir empfehlen dir LinkedIn Premium zu nutzen, um deine Nutzung des SSCs möglichst reibungslos und effizient zu gestalten.',
  i_dont_use_external_tools:
    'Ich nutze keine externen Tools, Bots oder Extension mit LinkedIn außer das SSC',
  im_ready_to_find_customers: 'Ich bin bereit Kunden zu gewinnen',
  linkedin_detecting_warning:
    'LinkedIn hat eine strikte Erkennung von Bots und externen Tools. Das SSC ist kein Bot und hilft dir nur deinen Workflow zu optimieren, allerdings kann es trotzdem passieren, dass LinkedIn uns soft-bannt, also den Zugang für einen Zeitraum sperrt. Wir empfehlen dir LinkedIn Premium zu kaufen, da dies dann nicht mehr vorkommt, du kannst das SSC allerdings auch ohne LinkedIn Premium nutzen.',
  ssc_now_setup: 'Das Social Selling Cockpit ist nun eingerichtet',
  setup_finished: 'Setup abgeschlossen',
  terms_of_use: 'Nutzungsbedingungen',
  you_need_to_accept_tos: (
    <>
      Um das <strong>Social Selling Cockpit</strong> musst Du die
      Nutzungsbedingungen und die Datenschutzbestimmungen akzeptieren.
    </>
  ),
  // %% are intentional! -> surround word that contains link with %%
  i_accept_tos: 'Ich akzeptiere die %%Nutzungsbedingungen%%',
  // %% are intentional! -> surround word that contains link with %%
  i_accept_data_privacy: 'Ich akzeptiere die %%Datenschutzerklärung%%',
  you_cant_use_without_accepting_tos:
    'Bitte stimmen Sie den Nutzungsbedingungen und der Datenschutzerklärung zu, andernfalls ist die Nutzung der Software leider nicht möglich.',
  tell_us_about_yourself: 'Erzähl uns kurz was über dich!',

  ava_hint_setup_term: (
    <span>
      <strong>Hey, willkommen an Bord des Social Selling Cockpits! ✈</strong>
      <br />
      Bevor wir abheben können, schau dir bitte kurz die Nutzungsbedingungen an.
      Ja, ich weiß, das klingt nach bürokratischem Kleingedrucktem, aber es ist
      wichtig. Nimm dir bitte einen Moment Zeit, und wenn alles klar ist, sind
      wir bereit zum Start
    </span>
  ),
  ava_hint_setup_survey:
    'Um die Effizienz unseres Cockpits zu maximieren, brauchen wir dein Feedback! 📝 Teile uns mit, wofür du das SSC nutzen möchtest. Damit helfen wir dir besser und können dein Social Selling Cockpit weiter verbessern.',
  ava_hint_manual: (
    <span>
      <strong>Wilkommen im Manuellen Modus!</strong> <br />
      Hier kannst du zielgenauer mit einzelnen Kontakten im Chat Cockpit
      schreiben, deine Nachrichten Vorlagen überarbeiten, deine persönlichen
      Kontakte pflegen oder auf LinkedIn Beiträge und Kommentare schreiben. Der
      Manuelle Modus leitet dich nicht wie der Fokus Modus von Aufgabe zu
      Aufgabe, er ist aber sehr gut, wenn du dich auf einzelne Kontakte
      konzentrieren möchtest.
    </span>
  ),
  ava_hint_focus_expand_network: (
    <>
      {/* <AvaYoutubeVideo videoID="OhAVGztMuQI" /> */}
      <span>
        In diesem Schritt geht es nicht um Perfektion, sondern um{' '}
        <strong style={{ marginLeft: '.25em' }}>Tempo.</strong>
      </span>
      <span style={{ marginTop: '8px' }}>
        Entspricht das Profilbild deinen Erwartungen? Lässt der Slogan des
        Profils darauf schließen, dass es sich um einen potenziellen Kunden
        handeln könnte?
      </span>
      <span style={{ marginTop: '8px' }}>
        Wenn du unsicher bist, markiere das Profil einfach. Wir werden später
        genauer hinschauen.
      </span>
      <span style={{ marginTop: '8px' }}>
        Dein Ziel sollte sein, in weniger als drei Minuten 50 potenzielle Kunden
        zu markieren. Kannst du diese Herausforderung meistern?
      </span>
    </>
  ),
  ava_hint_focus_categorize: (
    <>
      <span>
        Was bringt dir ein riesiges Netzwerk, wenn du nicht weißt, wer deiner
        Kontakte ein potenzieller Kunde, ein Netzwerker oder nur ein Bekannter
        ist?
      </span>
      <span style={{ marginTop: '8px' }}>
        Besonders, wenn du mehr als 500 Kontakte hast. Und wann sprichst du
        wieder mit ihnen?
      </span>
      <span style={{ marginTop: '8px' }}>
        Das Herzstück des Social Selling Cockpit ist die Kategorisierung deiner
        Kontakte. Nur wenn klar ist, mit wem es sich lohnt, weiter zu schreiben
        oder in den Dialog zu treten, wird deine Kundengewinnung klar und
        effektiv.
      </span>
      <span style={{ marginTop: '8px' }}>
        Überspringe diesen Schritt nicht und nimm dir die Zeit, hier gründlich
        zu arbeiten.
      </span>
      <span style={{ marginTop: '8px' }}>Dein Umsatz wird es dir danken.</span>
    </>
  ),
  ava_hint_focus_chat: (
    <>
      <span>
        Was ist beim Chatten wichtig? Wie machst du Termine aus und wie schaffst
        du es, den Kunden bereits hier auf eine Zusammenarbeit einzustimmen?
      </span>
      <span style={{ marginTop: '8px' }}>
        Indem du diese drei Schritte befolgst:
      </span>
      <ul style={{ listStyle: 'numeric', paddingInlineStart: 2 }}>
        <li>
          Behalte im Hinterkopf, dass du mit einem Menschen sprichst - nicht mit
          einem Computer.
        </li>
        <li>
          Baue eine Beziehung auf. Gib deinem Gesprächspartner Raum und Zeit,
          sich zu öffnen.
        </li>
        <li>
          Führe eine interessante Unterhaltung. Langweile deinen
          Gesprächspartner nicht mit monotonen Fragen und Antworten.
        </li>
      </ul>
      <span style={{ marginTop: '8px' }}>
        Und der Pro-Tipp: Entwickle Neugier, Spaß und ein Gefühl von Abenteuer.
        Du bist gerade dabei, voll abzuheben und auch dein Leben besser zu
        machen.
      </span>
      <span style={{ marginTop: '8px' }}>
        Wäre doch optimal wenn du das auch noch genießen kannst, oder?
      </span>
    </>
  ),
  ava_hint_focus_connect_profile: (
    <>
      <span>
        Richtig! Nicht jeder LinkedIn Nutzer passt als dein Kunde, und das ist
        okay. Wenn du noch unsicher bist, wer dein Idealkunde auf LinkedIn sein
        könnte, versuche es anhand dieser Eckdaten zu identifizieren:
      </span>
      <ul style={{ listStyleType: '-', paddingInlineStart: 2 }}>
        <li>In welcher Branche ist dein Idealkunde tätig?</li>
        <li>Welche Position hat er/sie inne?</li>
        <li>Wie lange ist er/sie schon in dieser Position?</li>
      </ul>
      <span style={{ marginTop: '8px' }}>
        <strong style={{ marginRight: '.25em' }}>WICHTIG:</strong> Dein
        Idealkunde auf LinkedIn sollte regelmäßig auf der Plattform aktiv sein.
        Das bedeutet nicht unbedingt, dass er/sie selbst Beiträge
        veröffentlicht, aber er/sie sollte auf Beiträge reagieren oder
        kommentieren.
      </span>
      <span style={{ marginTop: '8px' }}>
        <strong style={{ marginRight: '.25em' }}>Tipp:</strong> Mit etwas Übung
        kannst du oft innerhalb von Sekunden am Profilbild, Titelbild, Slogan
        und der Profilbeschreibung erkennen, ob jemand ein potenzieller Kunde
        sein könnte.
      </span>
      <span style={{ marginTop: '8px' }}>
        Vertraue dabei auf dein Bauchgefühl.
      </span>
    </>
  ),
  ava_hint_focus_question: (
    <>
      <span>
        Das Social Selling Cockpit möchte dich dabei unterstützen, deinen
        Kundengewinnungsprozess erfolgreich zu gestalten.
      </span>
      <span style={{ marginTop: '8px' }}>
        Um das zu gewährleisten, brauchen wir ein paar Informationen, z.B. wie
        du die Beziehung mit deinem Kunden einschätzt oder wie deine Meetings
        verlaufen sind.
      </span>
      <span style={{ marginTop: '8px' }}>
        Diese Informationen helfen uns, dich daran zu erinnern, entweder
        nachzufassen oder die nächsten Schritte im Prozess einzuleiten.
      </span>
      <span style={{ marginTop: '8px' }}>
        So sorgen wir dafür, dass du nie vergisst, dich bei deinem Kunden zu
        melden
      </span>
      <span style={{ marginTop: '8px' }}>
        Bleib dran, solange du das SSC nutzt
      </span>
    </>
  ),
  ava_hint_focus_audience_holder: (
    <>
      <span>
        Jetzt wird's spannend! Deine nächste Aufgabe besteht darin, mindestens 5
        Zielgruppenhalter auszuwählen.
      </span>
      <span
        style={{ fontWeight: 'bold', marginTop: '16px', marginBottom: '8px' }}>
        Wie geht das am besten?
      </span>
      <ul style={{ listStyleType: '-', paddingInlineStart: 2 }}>
        <li>
          Mach dich auf Entdeckungsreise durch deinen LinkedIn Newsfeed.
          Vielleicht findest du gleich den einen oder anderen geeigneten
          Kandidaten.
        </li>
        <li>
          Nutze die Suchfunktion, um gezielt nach potenziellen
          Zielgruppenhaltern zu suchen.
        </li>
        <li>
          Oder versuche es mit Schlagwortsuche! Gib ein relevantes Schlagwort
          wie "Social Selling" ein und klicke auf den Reiter "Beiträge".
        </li>
      </ul>
      <span style={{ marginTop: '8px' }}>
        <strong style={{ marginRight: '.25em' }}>
          Kleiner Tipp von Co-Pilotin Ava:
        </strong>{' '}
        Ein guter Zielgruppenhalter hat im Schnitt über 30 Reaktionen,
        mindestens 5 Kommentare und postet mindestens 2-3 mal pro Woche
        relevante Beiträge.
      </span>
    </>
  ),

  hiding_already_known_profiles: 'bereits bekannte Profile werden ausgeblendet',
  reposts: 'Reposts',
  add_audience_holder: 'Zielgruppenhalter hinzufügen',
  remove_audience_holder: 'Zielgruppenhalter entfernen',
  mark_profile: 'Vormerken',
  unmark_profile: 'Nicht mehr vormerken',

  pipe_meet_name: 'Kennenlernen',
  pipe_meet_description:
    'Die erste Pipeline. Hier lernst du neue Leute kennen und kannst dich mit ihnen austauschen.',
  welcome_and_establish_relation: 'Begrüßung und Beziehungsaufbau',
  welcome_and_establish_relation_description:
    'Hier geht es darum, die Person kennenzulernen und eine authentische Beziehung aufzubauen. Hier willst du noch nicht verkaufen.',
  meeting_pitch: 'Meeting Pitch',
  meeting_pitch_description:
    'Ihr habt euch jetzt schon in ein paar Nachrichten kennengelernt. Da das noch besser in einem Gespräch geht, lädst du die Person jetzt zu einem Gespräch ein.',
  waiting_for_pitch_meeting: 'Warten auf eine Antwort',
  waiting_for_pitch_meeting_description:
    'Du hast den Meeting-Pitch geschickt. Jetzt musst du auf eine Antwort warten.',
  calendar_link: 'Kalender Link',
  calendar_link_description:
    'Die Person hat einem Meeting zugestimmt. Jetzt schickst du ihr einen Kalender Link zu (Bsp: Calendly), dann kann sie den Termin direkt in deinen Kalender eintragen.',
  meeting_accepted: 'Gespräch vereinbart',
  meeting_accepted_description:
    'Wurde das Gespräch schon gebucht? Wenn ja, dann trag es jetzt hier ein. Das Social Selling Cockpit wird dich dann am nächsten Tag fragen, wie es gelaufen ist.',
  when_is_the_meeting: 'Wann findet das Gespräch statt?',
  waiting_for_meeting: 'Warten auf das Meeting',
  waiting_for_meeting_description:
    'Das Meeting ist gebucht. Jetzt musst du nur noch warten, bis das Gespräch stattfindet.',
  meeting_done: 'Gespräch geführt',
  meeting_done_description:
    'Und wie war das Gespräch? Hast du was gelernt? Habt ihr euch gut verstanden?',
  how_was_meeting_result: 'Was war das Ergebnis des Gesprächs?',
  strategy_call_scheduled: 'Strategiegespräch vereinbart',
  you_scheduled_a_strategy_call: 'Ihr habt das Strategiegespräch vereinbart.',
  no_match_explanation:
    "Der Kontakt ist einfach nicht der richtige Kunde für dich. Entweder weil dein Angebot nicht passt oder weil es persönlich nicht matcht. Falls er nur gerade noch nicht groß genug ist, ist vielleicht die Option 'Falscher Zeitpunkt' die bessere.",
  wrong_time: 'Falscher Zeitpunkt',
  canceled: 'Abgesagt',
  did_not_show_up: 'Nicht erschienen',
  wrong_time_explanation:
    'Es ist gerade nicht der richtige Zeitpunkt für eine Zusammenarbeit.',
  did_not_show_up_explanation:
    'Der Kontakt ist nicht zum Meeting erschienen. Vielleicht ist ihm etwas dazwichen gekommen.',
  thanks_for_the_meeting_and_homework: 'Danke für das Gespräch + Hausaufgaben',
  thanks_for_the_meeting_and_homework_description:
    'Du hast ein Strategiegespräch vereinbart. Bedanke dich bei der Person für das letzte Gespräch und schicke ihr ggf. Material oder Hausaufgaben zu.',
  prepare_for_strategy_meeting:
    'Das Gespräch ist gebucht und eingetragen. Bereite dich jetzt auf das Gespräch vor.',
  when_is_the_strategy_meeting: 'Wann findet das Strategiegespräch statt?',
  waiting_for_strategy_meeting: 'Warten auf das Strategiegespräch',
  waiting_for_strategy_meeting_description:
    'Das Strategiegespräch ist gebucht. Jetzt musst du nur noch warten, bis das Gespräch stattfindet.',
  strategy_meeting_done: 'Strategiegespräch geführt',
  strategy_meeting_done_description: 'Und, verkauft?',

  messages_after_no_show: 'Nachricht nach dem Nicht-Erscheinen',
  messages_after_no_show_description:
    'Die Person ist nicht zum Meeting erschienen. Schreib ihr eine Nachricht, um zu fragen, was passiert ist.',
  waiting_for_response: 'Warten auf eine Antwort',
  waiting_for_response_to_no_show_message_description:
    'Du hast nachgefragt, warum die Person nicht zum Meeting erschienen ist. Jetzt musst du auf eine Antwort warten.',
  where_do_we_go_from_here: 'Wie geht es jetzt weiter?',
  you_got_no_answer_what_now:
    'Du hast eine Antwort bekommen. Wie geht es jetzt weiter?',
  new_appointment: 'Neuer Termin',
  new_appointment_explanation:
    'Du bist bereit dem Kontakt eine zweite Chance zu geben. Dann vereinbart ihr im nächsten Schritt einen neuen Termin.',
  make_new_appointment: 'Neuen Termin vereinbaren',
  make_new_appointment_description:
    'Du hast entschieden, dass der Kunde einen neuen Termin vereinbaren soll. Schick ihm nochmal deinen Kalender Link zu (Bsp: Calendly).',

  message_after_wrong_time: 'Nachricht nach dem falschen Zeitpunkt',
  message_after_wrong_time_description:
    'Die Person hat gesagt, dass es gerade nicht passt. Schick ihr jetzt eine Nachricht, um zu fragen, wann es besser passt.',
  time_to_retry: 'Zeitpunkt zum Nachfassen',
  this_is_the_wrong_time:
    'Ihr habt festgestellt, dass gerade der falsche Zeitpunkt ist. Jetzt setzen wir noch gerade fest, wann wir uns nochmal melden.',
  when_do_you_want_to_message_again:
    'Wann sollen wir uns nochmal melden? (Normalerweise 1 Monat später)',
  waiting_for_first_followup: 'Warten auf den ersten Follow-Up',
  we_wait_for_first_followup:
    'Wir warten darauf, dass der erste Follow-Up Termin erreicht ist.',
  first_followup: 'Erster Follow-Up',
  first_followup_description:
    'Wir haben den ersten Follow-Up Termin erreicht. Jetzt müssen wir uns nochmal melden.',
  first_followup_sent_waiting_for_response:
    'Wir haben die erste Follow-Up Nachricht geschickt. Jetzt müssen wir auf eine Antwort warten.',
  response_to_first_followup: 'Antwort auf den ersten Follow-Up',
  we_got_a_response_to_first_followup:
    'Wir haben eine Antwort auf den ersten Follow-Up bekommen. Jetzt müssen wir entscheiden, wie es weiter geht.',
  right_time: 'Passender Zeitpunkt',
  the_time_is_right_we_continue:
    'Der Zeitpunkt ist jetzt passend und du kannst mit den Verkaufsprozess fortsetzen.',

  second_follow_up: 'Zweiter Follow-Up',
  we_reached_second_follow_up:
    'Wir haben den zweiten Follow-Up Termin erreicht. Jetzt müssen wir uns nochmal melden.',
  waiting_for_second_followup: 'Warten auf den zweiten Follow-Up',
  we_wait_for_second_followup:
    'Wir warten darauf, dass der zweite Follow-Up Termin erreicht ist.',
  we_send_second_followup:
    'Wir haben die zweite Follow-Up Nachricht geschickt. Jetzt müssen wir auf eine Antwort warten.',
  response_to_second_followup: 'Antwort auf den zweiten Follow-Up',
  we_got_a_response_to_second_followup:
    'Wir haben eine Antwort auf den zweiten Follow-Up bekommen. Jetzt müssen wir entscheiden, wie es weiter geht.',

  waiting_for_third_followup: 'Warten auf den dritten Follow-Up',
  we_wait_for_third_followup:
    'Wir warten darauf, dass der dritte Follow-Up Termin erreicht ist.',
  third_followup: 'Dritter Follow-Up',
  we_reached_third_followup:
    'Wir haben den dritten Follow-Up Termin erreicht. Jetzt müssen wir uns nochmal melden.',
  waiting_for_third_followup_response:
    'Wir haben die dritte Follow-Up Nachricht geschickt. Jetzt müssen wir auf eine Antwort warten.',
  response_to_third_followup: 'Antwort auf den dritten Follow-Up',
  we_tried_three_times_did_it_work:
    'Wir haben jetzt drei Mal nachgefasst. Entweder der Kunde hat jetzt Zeit oder es ist kein Match. Das Social Selling Cockpit wird nicht weiter nachfassen.',
  you_got_a_customer: 'Der Konakt ist jetzt dein Kunde. Hurra!',

  lift_off: 'Jetzt abheben!',
  notes: 'Notizen',
  focused: 'Fokussiert',
  archived: 'Archiviert',
  full_focus_mode_title: 'VOLLFOKUS: Den gesamten Fokus-Modus am Stück',
  recommended: 'Empfehlung!',
  this_mode_has_no_focus:
    'Dieser Modus hat keinen Fokus, hier werden dir alle Aufgaben angezeigt.',
  tidy_network: 'Netzwerk aufräumen',
  categorize_your_contacts:
    'Kategorisiere deine Kontakte, um den Überblick zu behalten.',
  this_mode_focuses_on_categorizes_existing_contacts:
    'Der Fokus beim Netzwerk aufräumen liegt auf dem Kategorisieren deiner Kontakte und Verbindungen.',
  strengthen_relations: 'Beziehungen ausbauen',
  chat_with_contacts_and_strengthen_relations:
    'Chatte mit deinen bestehenden Konakten und baue Beziehungen aus.',
  the_most_important_part_of_social_selling_are_relations:
    'Das wichtigste beim Social Selling ist es, authentisch zu sein und so Beziehungen aufzubauen. Chatte mit deinen Kontakten und baue eine Beziehung mit ihnen auf.',
  find_new_network_participants: 'Finde neue Kontakte für dein Netzwerk',
  to_gain_customers_you_need_to_expand_your_network:
    'Um immer wieder neue Kunden zu gewinnen, solltest du stets dein Netzwerk erweitern, um mit neuen Personen in Kontakt zu kommen. Suche dafür potentielle Kunden auf LinkedIn raus und schaue sie dir dann nochmal genauer an, bevor du dich mit ihnen vernetzt',
  language: 'Sprache',
  you_need_to_restart_to_update_language:
    'Du musst das SSC neu starten, um die Sprache zu ändern. Willst du fortfahren?',
  save_and_restart: 'Speichern und Neustarten',

  welcome_message: 'Begrüßungsnachricht',
  welcome_message_desciption:
    'Hierbei handelt es sich um die erste Nachricht, die du an einen neuen Kontakt sendest. Hier stellst du dich am besten kurz vor und stellst eine Frage, um die Konversation zu starten.',
  relation_building_message: 'Beziehungsnachricht',
  relation_building_message_description:
    'Hier geht es darum eine authentische Beziehung zum Kontakt aufzubauen. Es ist immer wichtig auf die Antwort des anderen einzugehen und die Konversation am Laufen zu halten.',

  homework_for_strategy_call: 'Hausaufgabe fürs Strategiegespräch',
  homework_for_strategy_call_description:
    'Du hattest gerade das erste Gespräch mit deinem Kontakt und ihr habt ein Strategiegespräch ausgemacht. Jetzt schickst du ihm noch eine Nachricht mit einer kleinen Hausaufgabe, damit er sich auf das Gespräch vorbereiten kann.',

  calendar_link_no_show: 'Kalender Link nach nicht erscheinen',
  calendar_link_no_show_description:
    'Der Kontakt ist zu eurem ersten Termin nicht erschienen. Jetzt möchtest du ihm nochmal eine Chance geben und schickst ihm nochmal deinen Kalender Link (bzw. Terminvorschläge).',
  follow_up: 'Follow-Up',
  follow_up_description:
    'Der Kontakt hat nicht auf deine letzte Nachricht geantwortet. Ist sie einfach untergegangen? Mit dieser Nachricht findest du es heraus.',
  uncategorized_template_description:
    'Diesen Kontakt hast du noch nicht kategorisiert. Schreib ihm eine Nachricht, um ihn besser kennenzulernen und/oder dich vorzustellen.',

  about_ssc: 'Über das Social Selling Cockpit',
  quit: 'Beenden',
  undo: 'Rückgängig',
  redo: 'Wiederholen',
  cut: 'Ausschneiden',
  copy: 'Kopieren',
  paste: 'Einfügen',
  paste_and_match_style: 'Einfügen und anpassen',
  select_all: 'Alles auswählen',
  fullscreen: 'Vollbild',
  minimize: 'Minimieren',
  to_front: 'In den Vordergrund',
  window: 'Fenster',
  help: 'Hilfe',
  ssc_terminal: 'SSC-Terminal',
  website: 'Webseite',
  version: 'Version',
  support_email: 'Support E-Mail',
  open_link_in_browser: 'Link im Browser öffnen',
  copy_link: 'Link kopieren',
  search_with_google: 'Mit Google suchen',
  view: 'Ansicht',
  devtools: 'Entwicklerwerkzeuge',
  debugger: 'Debugger',
  edit: 'Bearbeiten',
  advanced_options: 'Erweiterte Optionen',
  web_auth: 'Web Auth',
  web_auth_description:
    'Wenn du beim LinkedIn Login einen Hardware Security Key, Gesichtserkennung oder Fingerabdruckscanner nutzt, musst diese Option aktiviert sein.',
  account_locked_payment_required:
    'Deine letzte Abozahlung war nicht erfolgreich. Bitte überprüfe deine Zahlungsinformationen und melde dich dann erneut an.',
  payment_missing: 'Zahlung fehlt',
  payment_missing_description:
    'Es gab einen Fehler beim Abbuchen deiner Abozahlung. Bitte gehe auf die SSC Seite und schließe das Abo erneut ab.',
  server_error: 'Server Fehler',
  login: 'Login',
  request_code: 'Code anfordern',
  max_discount_coupons_reached: 'Maximale Anzahl an Discount Coupons erreicht',
  max_free_to_use_coupons_reached:
    'Maximale Anzahl an Free To Use Coupons erreicht',
  webview_overtaken: 'Webview übernommen',
  webview_overtaken_description:
    'Wir sind gerade ein paar Daten aus LinkedIn an laden. Bitte warte einen Moment.',
  no_templates: 'Keine Vorlagen vorhanden',
  no_tags: 'Keine Tags vorhanden',
  add_tag: 'Tag hinzufügen',
  edit_tags: 'Tags bearbeiten',
  tags: 'Tags',
  tag_name: 'Name des Tags',
  tag_name_already_taken: 'Dieser Tag existiert bereits',
  delete_tag: 'Tag löschen',
  delete_tag_confirm:
    'Willst du diesen Tag wirklich löschen? Der Tag wird dann auch bei allen Kontakten gelöscht. Das kann nicht rückgängig gemacht werden.',
  manage_tags: 'Tags verwalten',
  opening_network_page: 'Öffne Netzwerk Seite',
  waiting_for_network_page_load: 'Warte auf Netzwerk Seite',
  getting_network_count: 'Lade Netzwerkgröße',
  got_network_count: 'Netzwerkgröße geladen',
  scrolling_network_page: 'Scanne Netzwerkseite',
  scrolled_network_page: 'Netzwerkseite gescannt',
  scrolling_entire_network: 'Scanne das gesamte Netzwerk',
  scrolled_entire_network: 'Gesamtes Netzwerk gescannt',
  scrolling_new_network: 'Suche nach neuen Einträgen im Netzwerk',
  open_chats_page: 'Öffne Chat Seite',
  started_checking_chats: 'Starte Chat Überprüfung',
  waiting_for_chat_to_load: 'Warte auf Chat',
  chat_loaded: 'Chat geladen',
  scrolling_chat: 'Scanne Chats',
  checking_for_new_messages: 'Suche nach neuen Nachrichten',
  finished_current_chat: 'Chat beendet',
  loading_next_chat_messages: 'Lade nächste Chat Nachrichten',
  finished_checking_for_new_messages: 'Suche nach neuen Nachrichten beendet',
  chat_popup_loaded: 'Chat Overlay geladen',
  opening_profile_page: 'Öffne Profil Seite',
  checking_profile_distance: 'Lade Connection Status des Kontakts',
  opening_message_popup: 'Öffne Nachrichten Popup',
  category: 'Kategorie',
  all: 'Alle',
  no_filter: 'Kein Filter',
  skipped: 'Übersprungen',
  not_skipped: 'Nicht übersprungen',
  pipeline_step: 'Pipeline Schritt',
  unanswered_messages: 'Unbeantwortete Nachrichten',
  no_unanswered_messages: 'Keine unbeantworteten Nachrichten',
  unread_messages: 'Ungelesene Nachrichten',
  no_unread_messages: 'Keine ungelesenen Nachrichten',
  n_tags_selected: (n: number) => `${n} Tags ausgewählt`,
  open_filter: 'Filter öffnen',
  close_filter: 'Filter schließen',
  error_linkedin_loading: 'Fehler beim Laden der Daten von LinkedIn',
  explanation_webview_loading_error:
    'Das Social Selling Cockpit greift normalerweise direkt Daten von der LinkedIn Webseite ab, um sicherzustellen, dass unsere Nutzer nicht von LinkedIn erkannt werden können und um deinen Account zu schützen.',
  webview_loading_not_working_explanation:
    'Leider scheint dies bei dir nicht zu funktionieren. Das kann z.B. an einer langsamen Internetverbindung, einem leistungsschwachen PC liegen oder an einer Änderung an der Struktur von LinkedIn liegen auf die wir uns noch nicht anpassen konnten. Versuche es bitte nochmal oder wende dich an unseren Support.',
  retry_load: 'Laden erneut versuchen',
  general: 'Allgemein',
  third_party_licenses: 'Lizenzen von Drittanbietern',
  loading_settings: 'Einstellungen zum Laden von Daten',
  safe_loading: 'Sicheres Laden',
  safe_loading_description:
    'Durch Mitlesen von Daten auf der LinkedIn Webseite machen wir es LinkedIn unmöglich Nutzer des Social Selling Cockpits zu erkennen. Das ist die sicherste Methode, um deinen LinkedIn Account zu schützen.',
  api_loading: 'Laden per API',
  api_loading_description:
    'Daten werden direkt von LinkedIn abgefragt. Das ist schneller, aber auch riskanter. Wenn du diese Option nutzt, können wir nicht garantieren, dass dein LinkedIn Account nicht gesperrt wird.',
  loading_network_participant: (name: string) =>
    `Lade Netwerkteilnehmer ${name}`,
  finished_loading_network: 'Netzwerk geladen',
  loading_messages_with: (name: string) =>
    `Lade Nachrichten aus Chat mit ${name}`,
  loading_message_from_chat_at_date: (
    name: string,
    date: Date,
    number?: number
  ) =>
    `Lade Nachricht ${
      number ? `#${number} ` : ''
    }aus Chat mit ${name} vom ${new Date(date).toLocaleDateString(
      LanguageManager.getInstance().getLanguage(),
      {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }
    )}`,
  finished_loading_messages: 'Nachrichten geladen',
  error_while_loading_linkedin_data:
    'Beim Laden der Profildaten von LinkedIn ist ein Problem aufgetreten.',
  error_while_loading_categorization_data_choice:
    'Du kannst die Person jetzt überspringen und es später nochmal probieren oder die Person löschen und sie dann nicht mehr im SSC sehen oder ihr schreiben.',
  count: 'Anzahl',
  time: 'Zeit',
  next: 'Weiter',
  options: 'Optionen',
  task_options_explanation:
    'Wie viel Durchgänge möchtest du machen oder wie oft möchtest du diesen Task machen?',
  question: 'Frage',
  max_reached: 'Du hast das Maximum erreicht',
  goal_reached: 'Du hast dein Ziel erreicht',
  one_more: 'Einer noch',
  you_reached_max_time: 'Die Zeit ist abgelaufen',
  one_more_minute: 'Noch eine Minute',
  error_while_saving_audience_holder_data:
    'Fehler beim Speichern der Profile deiner Zielgruppenhalter',
  max_connections_explanation:
    'LinkedIn erlaubt nur eine begrenzte Anzahl an Verbindungsanfragen. Deswegen beschränken wir dein Maximum, um dich vor einem Soft-Ban zu schützen.',
  build_a_personal_relation:
    'Baue eine persönliche Beziehung zu deinen Kontakten auf.',
  write_post: 'Schreibe einen Beitrag',
  write_post_description:
    'Mit Beiträgen kannst du deine Reichweite auf LinkedIn extrem erhöhen. Schreibe einen Beitrag, der deine Zielgruppe anspricht und poste ihn auf LinkedIn.',
  write_post_time_explanation:
    'Wie lange möchtest du an deinem Beitrag schreiben?',
  open_linkedin: 'LinkedIn öffnen',
  contea_description:
    'Contea ist ein AI Tool mit dem du einfach und super schnell authentische LinkedIn Beiträge erstellen kannst',
  person: 'Person',
  confirm_relation_established: (name: string) =>
    `Hast du eine Beziehung zu ${name} aufgebaut?`,
  close_chat: 'Chat schließen',
  audience_holder_saved: (name: string) =>
    `Zielgruppenhalter ${name} gespeichert`,
  audience_holder_removed: (name: string) =>
    `Zielgruppenhalter ${name} entfernt`,
  audience_holder_not_saved: `Zielgruppenhalter konnte nicht gespeichert werden`,
  newest_version_installed: 'Neuste Version installiert',
  error_reaching_linkedin: 'Fehler beim Erreichen von LinkedIn',
  linkedin_error_restart_or_logout:
    'Wir können leider LinkedIn nicht erreichen. Das kann zwei Gründe haben. Entweder hast du gerade kein Internet oder du wurdest von LinkedIn ausgeloggt.\nBitte überprüfe, ob du mit dem Internet verbunden bist und starte dann neu oder logge dich bei LinkedIn aus.',
  restart: 'Neu starten',
  confirm_delete_mode: (modeTitle: string) =>
    `Focus Modus ${modeTitle} löschen`,
  confirm_delete_mode_description:
    'Bist du dir sicher, dass du diesen Modus löschen willst? Das kann nicht rückgängig gemacht werden.',
  save_as_preset: 'Als Preset speichern',
  save_as_preset_description:
    'Speichere diesen Modus als Preset, um die Einstellungen später schnell wieder nutzen zu können.',
  description: 'Beschreibung',
  confirm: 'Bestätigen',
  checking_login_status: 'Überprüfe Login Status',
  unknown_server_error_description:
    'Es gab einen unbekannten Fehler auf dem Server. Bitte versuche es später nochmal.',
  unknown_error_description:
    'Es gab einen unbekannten Fehler. Bitte versuche es später nochmal.',
  invalid_linkedin_account: 'Ungültiger LinkedIn Account',

  ssc_login_linkedin_account_missmatch: 'LinkedIn Account Fehler',
  ssc_login_linkedin_account_missmatch_explanation:
    'Du bist auf diesem Gerät noch mit einem LinkedIn Account eingeloggt. Dein SSC-Account kann allerdings nur mit einem LinkedIn Account verknüpft werden. Melde dich bitte entweder bei LinkedIn ab oder melde dich mit einem anderen SSC-Account an.',
  invalid_refresh_token: 'Ungültige Login Session',
  invalid_refresh_token_description:
    'Deine Login Session ist abgelaufen. Bitte melde dich erneut an.',
  payment_required: 'Zahlung erforderlich',
  payment_failed: 'Zahlung fehlgeschlagen',
  payment_warning_description:
    'Deine Zahlung ist fehlgeschlagen. Bitte überprüfe deine Zahlungsinformationen und versuche es später erneut. Du kannst aktuell das SSC noch weiter nutzen, falls die Zahlungen weiter fehlenschlagen, wird dein Account allerdings gesperrt.',
  payment_error_description:
    'Es gab einen Fehler bei deiner Zahlung. Dein Abo wurde deswegen gekündigt. Du kannst aber hier das Abo neu abschließen, um das SSC weiter zu nutzen.',
  subscription_cancelled: 'Abo gekündigt',
  subscription_cancelled_description:
    'Dein Abo wurde gekündigt. Du kannst das SSC nur noch bis zum Ende deines aktuellen Abos weiter nutzen.',
  subscription_expired: 'Abo abgelaufen',
  subscription_expired_description:
    'Dein Abo ist abgelaufen. Um das SSC weiter nutzen zukönnen, musst du dein Abonnement erneuern.',
  subscription_paused: 'Abo pausiert',
  subscription_paused_description:
    'Dein Abo wurde pausiert. Um das SSC weiter zu nutzen, musst du dein Abonnement erneuern.',
  no_subscription: 'Kein Abo',
  no_subscription_description:
    'Du hast aktuell kein Abo. Um das SSC weiter zu nutzen, musst du ein Abo abschließen.',

  resubscribe: 'Abo erneuern',
  subscribe: 'Abo abschließen',
  linkedin_logout: 'Aus LinkedIn ausloggen',
  ssc_logout: 'Aus dem SSC ausloggen',
  back_to_login: 'Zurück zum Login',

  issue_with_audience_holders:
    'Es gibt ein Problem mit deinen Zielgruppenhaltern',
  trying_to_fix_the_issue:
    'Wir versuchen das Problem zu beheben. Bitte habe einen Moment Geduld.',
  following_audience_holders_are_broken:
    'Bei folgende Zielgruppenhalter sind Probleme aufgetreten:',
  what_do_you_want_to_do_with_the_broken_audience_holders:
    'Was möchtest du mit den defekten Zielgruppenhaltern machen?',
  ignore_this_time: 'Dieses Mal ignorieren',
  delete_these_audience_holders: 'Diese Zielgruppenhalter löschen',
  save_profile: 'Profil speichern',
  no_connection: 'Keine Verbindung',
  no_connection_desc:
    'Möglicherweise ist ihr Gerät nicht mit dem Internet verbunden. Bitte überprüfen Sie Ihre Verbindung. Wenn das Problem weiterhin besteht, wenden Sie sich an den Support.',
  server_unavailable: 'Server nicht verfügbar',
  server_unavailable_desc:
    'Leider sind die SSC Server momentan nicht erreichbar. Wir arbeiten daran das Problem zu beheben. Bitte versuche es später erneut.',
  marked: 'Vorgemerkt',

  new_version_available: 'Neue Version verfügbar',
  update_required_explanation:
    'Da es ein paar wichtige Änderungen gab, musst du die neuste Version installieren, um das SSC weiter nutzen zu können.',
  download_new_version: 'Neue Version herunterladen',

  confirm_clear_filter: 'Willst du alle Filter zurücksetzen?',
  clear_filter: 'Filter zurücksetzen',

  cant_use_filters_with_linkedin_chat:
    'Filter können nicht im LinkedIn Chat genutzt werden',
  normal: 'Normal',

  max_reactions_for_message:
    'Maximale Anzahl an Reaktionen für eine Nachricht erreicht',

  load_posts: 'Erweitertes Laden',
  load_posts_description:
    'Um die Interaktion deiner Kontakte mit deinen Inhalten zu tracken, müssen wir einmal deine letzten Posts laden. Das kann ein paar Minuten dauern, vor allem, wenn du viele Reaktionen oder Kommentare auf deine Beiträge kriegst. Diesen Prozess solltest du einmal am Tag machen, um immer die aktuellsten Daten zu haben. Mit den Daten können wir dir deine potentiellen Kunden zu priorisieren, wenn sie mit deinen Beiträgen interagieren.',
  loading_posts: 'Posts werden geladen',
  confirm_leave_load_posts:
    'Bist du dir sicher, dass du das Laden abbrechen willst? Um alle Daten zu laden, musst beim nächsten Mal wieder von vorne anfangen.',
  post_loading_finished: 'Posts fertig geladen',
  error_while_loading_posts: 'Fehler beim Laden der Posts',
  error_while_loading_posts_description:
    'Während des Ladens der Posts ist ein Fehler aufgetreten. Wir werden uns den Fehler anschauen und möglichst schnell beheben. Du kannst es nochmal versuchen oder zurück zum manuelle Modus gehen.',
  try_again: 'Nochmal versuchen',
  noInstances: (taskname: string) => `${taskname} - nichts zu tun`,
  noInstancesDescription:
    'Hier gibt es gerade nichts zu tun. Mach einfach mit der nächsten Aufgabe weiter und schaue später nochmal rein.',
  noMoreInstances: (taskname: string) => `${taskname} - das war's`,
  noMoreInstancesDescription:
    'Es gibt keine weiteren Kontakte mehr, diese Aufgabe ist damit abgeschlossen.',
  comments_posted: 'Gepostete Kommentare',
  connection_requests_sent: 'Gesendete Verbindungsanfragen',
  messages_sent: 'Gesendete Nachrichten',
  profiles_categorized: 'Kategorisierte Profile',
  profiles_marked: 'Vorgemerkte Profile',
  your_achivements: 'Deine Erfolge',
  your_focus_mode_progress: 'Dein Fokus-Modus Fortschritt',
  stats: 'Statistiken',
  last_focus_mode_stats: 'Letzte Fokus-Modus',
  today_stats: 'Heute',
  historic_stats: 'Historische Statistiken',
  last_week: 'Letzte Woche',
  week: 'Woche',
  last_month: 'Letzter Monat',
  month: 'Monat',
  last_quarter: 'Letztes Quartal',
  quarter: 'Quartal',
  last_year: 'Letztes Jahr',
  year: 'Jahr',
  see_my_stats: 'Fokus Modus beenden und Statistiken ansehen',
  goals: 'Ziele',
  skip_all_tutorials: 'Alle Tutorials überspringen',
  first_time_getting_data_might_take_a_while:
    'Beim ersten Start müssen wir ein paar Daten von LinkedIn abrufen. Dies könnte etwas länger dauern. Bitte habe etwas Geduld. Bei jedem weiteren Start wird dieses Laden deutlich schneller gehen.',
  start_focus_mode: 'Fokus Modus starten',
  pause_tutorial_continue_in_manual_mode:
    'Tutorial pausieren und im manuellen Modus fortfahren',
  error_loading_video: 'Beim Laden des Videos ist ein Fehler aufgetreten',
  restart_ssc: 'SSC neu starten',
  periodic_restart_ssc_explanation:
    'Damit das SSC reibungslos funktioniert, solltest du es regelmäßig neu starten. Da es seit gestern läuft, ist es an der Zeit, das SSC neu zu starten.',
  remind_in_2_min: 'In 2 Minuten erinnern',
  full_version_released: 'Social Selling Cockpit 1.0 ist da!',
  full_version_released_description:
    'Die Beta-Phase ist vorbei und das Social Selling Cockpit 1.0 ist da! Es gibt viele neue Features und Verbesserungen. Viel Spaß!',
  template_name_already_used:
    'Dieser Name wird bereits für eine andere Vorlage verwendet',
  apply_zoom_settings: 'Zoom Einstellung anwenden',
  confirm_zoom_settings: 'Möchtest du die Zoom Einstellungen anwenden?',
  home: 'Startseite',
  scrolling_profile_visits: 'Scanne Profilbesuche',
  loading_more_profile_visits: 'Lade mehr Profilbesucher',
  usage_time: 'Nutzungszeit',
  customer_count: 'Anzahl an Kunden',
  personal_contact_count: 'Anzahl an persönlichen Kontakten',
  no_match_count: 'Anzahl an nicht passenden Kontakten',
  audience_holder_count: 'Anzahl an Zielgruppenhaltern',
  marked_count: 'Anzahl an vorgemerkten Kontakten',
  potential_customer_count: 'Anzahl an potentiellen Kunden',
  build_relationship_count: 'Personen im Beziehungsaufbau Schritt',
  meeting_pitch_count: 'Personen im Meeting Pitch Schritt',
  calendar_link_count: 'Personen im Kalender Link Schritt',
  meeting_booked_count: 'Personen im Gespräch vereinbart Schritt',
  activities: 'Aktivitäten',
  categories: 'Kategorien',
  pipelines: 'Pipelines',
  there_are_no_stats_for_today_or_future:
    'Es gibt keine Statistiken für heute oder die Zukunft',
  this_template_has_a_response_rate_of: (rate: number) =>
    `Diese Vorlage hat eine Antwortrate von ${rate}%`,
  not_enough_message_with_template_sent_for_response_rate_calculation:
    'Es wurden nicht genug Nachrichten mit dieser Vorlage gesendet, um die Antwortrate zu berechnen.',
  load_entire_network: 'Gesamtes Netzwerk laden',
  confirm_leave_load_entire_network:
    'Willst du das Laden des gesamten Netzwerks abbrechen? Der Fortschritt ist gespeichert, wenn du allerdings mehr laden möchtest, musste du von vorne anfangen.',
  load_network_finished: 'Netzwerk vollständig geladen',
  error_while_loading_network: 'Fehler beim Laden des Netzwerks',
  error_while_loading_network_description:
    'Beim Laden des gesamten Netzwerks ist ein Fehler aufgetreten. Wir werden uns den Fehler anschauen und möglichst schnell beheben. Du kannst es nochmal versuchen oder zurück zum manuellen Modus gehen.',
  load_entire_network_description:
    'Hier kannst du dein gesamtes Netzwerk nachladen. Wir speichern normalerweise nur deine neueren Kontakte, um auf lange Ladezeiten verzichten zu können. Solltest du allerdings dein gesamtes Netzwerk im SSC nutzen wollen, kannst du es hier nachladen. ACHTUNG: Der Ladevorgang kann je nach Größe deines Netzwerkes sehr lange dauern!',
  load_network: 'Netzwerk laden',
  usage_warning: 'Nutzungswarnung',
  continue_anyways: 'Trotzdem fortfahren',
  usage_warning_profile_visits: (count: number) =>
    `Du hast heute bereits ${count} Profile besucht. Basierend auf deiner Angabe zur täglichen LinkedIn-Nutzung empfehlen wir dir, heute keine weiteren Profile zu besuchen, um eine Einschränkung deines Profils zu vermeiden.`,
  usage_warning_connect: (count: number) =>
    `Du hast dich heute bereits mit ${count} Profilen vernetzt. Basierend auf deiner Angabe zur täglichen LinkedIn-Nutzung empfehlen wir dir, heute keine weiteren Profile zu vernetzen, um eine Einschränkung deines Profils zu vermeiden.`,
  usage_warning_message: (count: number) =>
    `Du hast heute bereits ${count} Nachrichten verschickt. Basierend auf deiner Angabe zur täglichen LinkedIn-Nutzung empfehlen wir dir, heute keine weiteren Nachrichten zu schreiben, um eine Einschränkung deines Profils zu vermeiden.`,
  usage_low: 'Niedrig',
  usage_low_description:
    'Du nutzt LinkedIn bis jetzt nur von Zeit zu Zeit und wenn auch nur für kurze Zeit.',
  usage_medium: 'Mittel',
  usage_medium_description:
    'Du nutzt LinkedIn regelmäßig, aber auch nicht jeden Tag und nicht mehr als 1-2h pro Tag.',
  usage_high: 'Hoch',
  usage_high_description:
    'Du nutzt LinkedIn täglich und auch mehrere Stunden pro Tag.',
  usage_fulltime: 'Vollzeit',
  usage_fulltime_description:
    'Du nutzt LinkedIn den ganzen Tag, fast jeden Tag.',
  usage_unlimited: 'Unbegrenzt',
  usage_unlimited_description:
    'Wenn du keine präventive Warnungen zum Schutz deines LinkedIn Accounts erhalten möchtest, kannst du diese Option wählen.',
  linkedin_usage: 'LinkedIn Nutzung',
  linkedin_usage_description:
    'Bitte wähle, wie viel du bis jetzt LinkedIn genutzt hast. Basierend auf deiner Auswahl werden wir dir präventive Warnungen geben, um deinen LinkedIn Account zu schützen.',
  you_can_change_your_selection_in_the_settings:
    'Du kannst deine Auswahl jederzeit in der Einstellungen ändern.',
  restart_due_to_inactivity:
    'Da du für mehrere Minuten inaktiv warst, haben wir die Verbindung zu LinkedIn geschlossen, um deinen Account zu schützen. Leider musst du nun das SSC neustarten, damit wir sicherstellen können, dass wir die neusten Daten von LinkedIn haben.'
} satisfies { [key: string]: React.ReactNode | React.FC<any> };

export default lang_de;
